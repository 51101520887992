import {handleResponse} from './utils/handleResponse';
const baseUrl = process.env.REACT_APP_API_URL;

export const userService = {
    login,
    logout,
    register,
    getProfile,
    handleResponse,
    getAll,
    remove
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                username,
                password
            }
        })
    };

    return fetch(`${baseUrl}/users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        })
}

// remove user from local storage to log user out
function logout() {
    localStorage.removeItem('user');
}

function getProfile() {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(`${baseUrl}/profile`, requestOptions)
        .then(handleResponse)
        .catch(err => {throw new Error('Failed to load profile')})
}

function getTokenFromLocal() {
    return JSON.parse(localStorage.getItem('user')).user.token;
}

// register user request
function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + getTokenFromLocal(),
         },
        body: JSON.stringify({user})
    };
    return fetch(`${baseUrl}/users`, requestOptions)
        .then(handleResponse)
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + getTokenFromLocal(),
        }
    };
    return fetch(`${baseUrl}/users`, requestOptions)
        .then(handleResponse)
}

function remove(userId) {
    console.log('REMOVE ' + userId);
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + getTokenFromLocal(),
        },
        body: JSON.stringify({userId})
    }
    return fetch(`${baseUrl}/users`, requestOptions)
        .then(handleResponse)
}