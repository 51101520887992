import React, { Component } from 'react';
import {adminActions} from "../../actions";
import {connect} from "react-redux";
import {compose} from 'redux';
import { Register } from '../auth/Register';
import { Input, Button } from '@material-ui/core';
// import { EditPlaceModal } from '../Modal/EditPlaceModal';
import { DocLoad } from './DocLoad';
import { PlacesPanel } from './PlacesPanel';
import {UserList} from './UserLits';

import './Admin.css';

class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doc: '',
            openEditModal: false,
            selectedPlace: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitDocLoad = this.submitDocLoad.bind(this);

    }

    async submitDocLoad(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        // const { doc } = this.state;
        const input = document.getElementById('fileInput');
        const data = new FormData();
        data.append('file', input.files[0]);

        await this.props.loadDoc(data);
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        console.log(value);
        this.setState({
            [name]: value
        });
    }

    render() {
        // const { doc } = this.state;
        return (
            <>
                <div className="container-fluid text-center">
                    <div className="sector">
                        <h2>Загрузить документ</h2>
                        <h4>Порядок полей в excel: Город, Район, Сектор, Улица, Дом, Кол-во ящиков, Кол-во подъездов, Этажность, Агент</h4>
                        <form name='docForm'>
                            <Input label="выберите файл" type="file" id="fileInput" name="doc" value={this.state.doc} onChange={this.handleInputChange}/>
                            <Button variant="outlined" color="default" size="large" onClick={this.submitDocLoad}> Загрузить </Button>
                        </form>
                    </div>
                    <div className="sector">
                        <DocLoad/>
                    </div>
                    <div className="sector">
                        <Register/>
                    </div>
                    <div className="sector">
                        <UserList/>
                    </div>
                    <div className="sector">
                        <PlacesPanel/>
                    </div>
                </div>
               
            </>
        )
    }
}

function mapState(state) {
    return {state}
}

const actionCreators = {
    loadDoc: adminActions.loadDoc,
};

const connectedAdminPage = compose(connect(mapState, actionCreators))(AdminPage);
export { connectedAdminPage as AdminPage };