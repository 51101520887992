import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import { placesActions } from '../../../actions';
import {connect} from "react-redux";

class DocLoad extends Component {

    render() {
        return (
            <div>
                <h3>Изменения базы</h3>
                <Button variant="outlined" onClick={this.props.download}>Скачать базу</Button>
            </div>
        )
    }
}

function mapState(state) {
    return state;
}

const actionCreators = {
    download: placesActions.download
};

const connected = connect(mapState, actionCreators)(DocLoad);
export {connected as DocLoad}
// export default DocLoad