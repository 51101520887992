import { userService } from '../services/user.service';
import { appConstants,history } from '../helpers';

import { alertActions } from './';

export const userActions = {
    login,
    logout,
    register,
    getProfile,
    getAll,
    remove
};

function login(username, password) {
    return dispatch => {
        dispatch(request({
            username
        }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/admin');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function request(user) {
        return {
            type: appConstants.LOGIN_REQUEST,
            user
        }
    }

    function success(user) {
        return {
            type: appConstants.LOGIN_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: appConstants.LOGIN_FAILURE,
            error
        }
    }
}

function logout() {
    userService.logout();
    history.push('/login');
    return { type: appConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));
        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    dispatch(alertActions.success('Пользователь успешно создан'));
                },
                error => {
                    console.log(error,'error--------------------------')
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function request(user) {
        return {
            type: appConstants.REGISTER_REQUEST,
            user
        }
    }
    function success(user) {
        return {
            type: appConstants.REGISTER_SUCCESS,
            user
        }
    }
    function failure(error) { return { type: appConstants.REGISTER_FAILURE, error } }
}

function getProfile() {
    userService.getProfile()
        .then(profile => {
            console.log('PROFILE', profile);
        })
}

function getAll() {
    return dispatch => {
        dispatch({type: appConstants.GET_USERS_REQUEST, users: []})

        userService.getAll()
            .then(res=> {
                dispatch({type: appConstants.GET_USERS_SUCCESS, users: res})
            },
            err => {
                dispatch({type: appConstants.GET_USERS_FAILURE, users: []})
                dispatch(alertActions.error(err.message))
            })
    }
}

function remove(userId) {
    return dispatch => {
        dispatch({type: appConstants.DELETE_USER_REQUEST})

        userService.remove(userId)
            .then(res => {
                dispatch({type: appConstants.DELETE_USER_SUCCESS, userId})
            },
            err => {
                dispatch({type: appConstants.DELETE_USER_FAILURE, userId})
                dispatch(alertActions.error(err.message))
            })
    }
}