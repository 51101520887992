import { v4 as uuid } from 'uuid';

import React, {Component} from 'react';
import { userActions } from '../../../actions';
import {connect} from "react-redux";
import {User} from './User';

import './UserLits.css'

class UserList extends Component {
    async componentDidMount() {
        await this.props.getAll();
    }

    render() {
        return (
            <div className="userList">
                <h3>Список пользователей</h3>
                <table className="userTable">
                    <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Роль</th>
                            <th>Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users?.map(user => <User key={uuid()} data={user} deleteHandler={userId => this.props.remove(userId)}/>)}
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapState(state) {
    const {users} = state.admin;
    return {users}
}

const actionCreators = {
    getAll: userActions.getAll,
    remove: userActions.remove
}

const connectedList = connect(mapState, actionCreators)(UserList);
export {connectedList as UserList};