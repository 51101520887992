export const appConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    LOGOUT: 'USERS_LOGOUT',
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    DOC_LOAD_REQUEST: 'DOC_LOAD_REQUEST',
    DOC_LOAD_SUCCESS: 'DOC_LOAD_SUCCESS',
    DOC_LOAD_FAILURE: 'DOC_LOAD_FAILURE',
    CALC_REQUEST: 'CALCULATE_REQUEST',
    CALC_SUCCESS: 'CALC_SUCCESS',
    CALC_FAILURE: 'CALC_FAILURE',
    PLACE_REMOVE: 'PLACE_REMOVE',
    SAVE_PLACE_REQUEST: 'SAVE_PLACE_REQUEST',
    SAVE_PLACE_SUCCESS: 'SAVE_PLACE_SUCCESS',
    SAVE_PLACE_FAILURE: 'SAVE_PLACE_FAILURE',
    GET_PLACES_REQUEST: 'GET_PLACES_REQUEST',
    GET_PLACES_SUCCESS: 'GET_PLACES_SUCCESS',
    GET_PLACES_FAILURE: 'GET_PLACES_FAILURE',
    UPDATE_PLACE_REQUEST: 'UPDATE_PLACE_REQUEST',
    UPDATE_PLACE_SUCCESS: 'UPDATE_PLACE_SUCCESS',
    UPDATE_PLACE_FAILURE: 'UPDATE_PLACE_FAILURE',
    DELETE_PLACE_REQUEST: 'DELETE_PLACE_REQUEST',
    DELETE_PLACE_SUCCESS: 'DELETE_PLACE_SUCCESS',
    DELETE_PLACE_FAILURE: 'DELETE_PLACE_FAILURE',
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',
    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE'
};