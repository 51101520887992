import React from 'react';
import { Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        witdh: '200px'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));

export const DeleteSubmitModal = ({
    open,
    setOpen,
    deleteHandler
}) => {
    const classes = useStyles();
    // const handleOpen = () => {
    //     setOpen(true);
    //   };
    
    //   const handleClose = () => {
    //     setOpen(false);
    //   };

    return(
        <Modal 
            open={open} 
            className={classes.modal} 
            onClose={() => setOpen(false)} 
            >
                <div className={classes.paper}>Вы действительно хотите удалить?
                    <Button variant="outlined" onClick={deleteHandler}>Да</Button>
                    <Button variant="outlined" onClick={()=>setOpen(false)}>Нет</Button>
                </div>
        </Modal>
    )
}
