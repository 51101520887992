import { placesService } from "../services/places.service";
import { appConstants } from "../helpers";
import { alertActions } from './';

const calculate = (data) => {
    return dispatch => {
        dispatch({type: appConstants.CALC_REQUEST});

        placesService.calculate(data)
            .then(data => {
                dispatch({type: appConstants.CALC_SUCCESS, data})
            })
            .catch(err => {
                dispatch({type: appConstants.CALC_FAILURE, err})
                dispatch(alertActions.error(err.message))
            })
    }
};

const remove = (id, data) => {
    return dispatch => {
        const filtered = data.filter(place => place.id !== id);
        dispatch({type: appConstants.PLACE_REMOVE, data: filtered})
    }
}

const save = (place) => {
    return dispatch => {
        dispatch({type: appConstants.SAVE_PLACE_REQUEST});

        placesService.save(place)
            .then(res => {
                dispatch({type: appConstants.SAVE_PLACE_SUCCESS, res})
                dispatch(alertActions.success('Сохранено'))
            },
            err => {
                dispatch({type: appConstants.SAVE_PLACE_FAILURE, err})
                dispatch(alertActions.error(err.message))
            })
    }
}

const update = (place) => {
    return dispatch => {
        dispatch({type: appConstants.UPDATE_PLACE_REQUEST});

        placesService.update(place)
            .then(res => {
                dispatch({type: appConstants.UPDATE_PLACE_SUCCESS, res})
                dispatch(alertActions.success('Сохранено'))
            },
            err => {
                dispatch({type: appConstants.UPDATE_PLACE_FAILURE, err})
                dispatch(alertActions.error(err.message))
            })
    }
}

const getPlaces = (options) => {
    return dispatch => {
        dispatch({type: appConstants.GET_PLACES_REQUEST});

        placesService.get(options)
        .then(res => {
            dispatch({type: appConstants.GET_PLACES_SUCCESS, data:res})
        },
        err => {
            dispatch({type: appConstants.GET_PLACES_FAILURE, err})
        })
    }
}

const _delete = (id) => {
    return dispatch => {
        dispatch({type: appConstants.DELETE_PLACE_REQUEST});

        placesService.remove(id)
        .then(res => {
            dispatch({type: appConstants.DELETE_PLACE_SUCCESS, res});
            dispatch(alertActions.success('успешно удалено'))
        },
        err => {
            dispatch({type: appConstants.DELETE_PLACE_FAILURE});
            dispatch(alertActions.error(err.message))
        })
    }
}

const download = () => {
    return dispatch => {
        placesService.downloadDoc()
        .then(res => {
            console.log(res);
        })
    }
}

export const placesActions = {
    calculate,
    remove,
    save,
    getPlaces,
    update,
    _delete,
    download
};