import React, { Component } from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';

import {Login} from './components/auth/Login';
// import { Register } from './components/auth/Register';
import {Home} from './components/Home/Home';

import { history } from './helpers';
import { PrivateRoute } from './components/PrivateRoute'
import { connect } from 'react-redux';
import { alertActions } from './actions';
import './App.css';
import {AdminPage} from "./components/Admin/Admin";
import { Header } from './components/Header';

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
// clear alert on location change
      this.props.clearAlerts();
    });
  }

  render() {
    const { alert } = this.props;
    return (
        <div>
          <Router history={history}>
          <Header/>
          {alert.message &&
          <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          
            <div>
              <Switch>
                <PrivateRoute roles={["user", "admin"]} exact path="/home" component={Home} />
                <PrivateRoute roles={["admin"]} exact path="/admin" component={AdminPage}/>
                <Route path="/login" component={Login} />
                <Redirect exact from="/" to="/home" />
                <Route path="*" render={() => <h1>PAGE NOT FOUND</h1>} />
              </Switch>
            </div>
          </Router>
        </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };