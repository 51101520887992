import React, { Component} from 'react';
import { connect } from 'react-redux';
import { Place } from './Place';
import { ExportCSV } from '../ExportCSV';
import { placesActions } from '../../actions/places.action';

import "./PlaceList.css";

class PlaceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }

    componentDidMount() {
        const {data} = this.state;
        this.setState(data);
        
    }

    formatData = () => {
        return this.props.data.map(place => {
            return {
                'Город': place.city,
                'Район': place.district,
                'Сектор': place.sector,
                'Улица': place.street,
                'Дом': place.house,
                'Кол-во почтовых ящиков': place.mailbox_amount,
                'Кол-во подъездов': place.entrance_amount,
                'Агент': place.agent === 'NULL' ? '' : place.agent
            }
        })
    }

    render() {
        const totals = {houses: 0, entrances: 0, mailbox: 0};
        if(this.props.data.length) {
            this.props.data.forEach(place => {
                totals.houses++;
                let entranceAmount = parseInt(place.entrance_amount);
                let mailboxAmount = parseInt(place.mailbox_amount);
                totals.entrances += isNaN(entranceAmount) ? 1 : entranceAmount;
                totals.mailbox += isNaN(mailboxAmount) ? 1 : mailboxAmount;
            })
        }
        return (
            <div>
                {this.props.data.length ? <div  className="placeListContainer">
                    
                    <div  className="placeList">
                    <ExportCSV csvData={this.formatData()} fileName={`Report${new Date().toISOString()}`}/>
                <table>
                    <thead><tr>
                        <th>Город</th>
                        <th>Район</th>
                        <th>Сектор</th>
                        <th>Улица</th>
                        <th>Дом</th>
                        <th>Ящики</th>
                        <th>Подъезды</th>
                        <th>Агент</th>
                        <th>Удалить</th>
                    </tr></thead>
                    <tbody>
                    {this.props.data.map(place => (<Place id={place.id} data={place} deleteHandler={() => this.props.remove(place.id, this.props.data)}/>))}
                    </tbody>
                </table> 
                </div>
                <div className='totals'>
                <table>
                    <thead>
                        <tr><th>ДОМОВ</th><th>ПОДЪЕЗДОВ</th><th>ЯЩИКОВ</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>{totals.houses}</td><td>{totals.entrances}</td><td>{totals.mailbox}</td></tr>
                    </tbody>
                </table>
                </div>
                </div> : <div></div>}


            </div>
        )
    }
}

const mapState = (state) => {
    const {places: {data}} = state;
    console.log('STATE MAPPED');
    return {data, state}
}

const actionCreators = {
    remove: placesActions.remove
}

const connectedPlaceList = connect(mapState, actionCreators)(PlaceList);
export {connectedPlaceList as PlaceList};