import React, {  Component} from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import { Map } from '../Map';
import { PlaceList } from '../PlacesList';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            data: props.data
        }
    }



    render() {
        return (
            <>
                <Map/>
                <PlaceList/>
            </>
        )
    }

}

function mapState(state) {
    const { places } = state;
    const { data } = places;
    return { data };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedHome = connect(mapState, actionCreators)(Home);
export { connectedHome as Home };