import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, MenuItem } from '@material-ui/core';

import { userActions } from '../../actions';
import './auth.css'

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            role: 'user',
            submitted : false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitRegister = this.submitRegister.bind(this);

    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    submitRegister(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { role, username, password } = this.state;
        if (username && password) {
            this.props.register({role, username, password});
        }
    }

    render(){
        // const { registering } = this.props;
        const {  username, password, submitted } = this.state;
        return(

            <div>
                <h2 className="text-center">Создание пользователя</h2>
                <form className="text-center">
                <div className="inputHandle">
                <TextField size="medium" value={this.state.username} display="block" name="username" onChange={this.handleInputChange} label="Имя пользователя" placeholder="username" variant="outlined"/>
                {submitted && !username && <div className="help-block">Введите имя пользователя</div> }
                </div>
                <div className="inputHandle">
                <TextField value={this.state.password} name="password" onChange={this.handleInputChange} label="Пароль" type="password" variant="outlined"/>
                </div>
                {submitted && !password && <div className="help-block">Введите пароль</div> }
                    
                <TextField id="select" size="medium" name="role" label="Роль" value={this.state.role} select onChange={this.handleInputChange}>
                    <MenuItem size="large" value="user">Пользователь</MenuItem>
                    <MenuItem size="large" value="admin">Администратор</MenuItem>
                </TextField>
                <div className="buttonHandle">
                    <Button variant="outlined" color="default" size="large" onClick={this.submitRegister} disabled={!username || !password}> Создать </Button>
                </div>
         
            </form></div>
        )
    }

}

function mapState(state) {
    const { registering } = state.register;
    return { registering };
}

const actionCreators = {
    register: userActions.register
}

const connectedRegister = connect(mapState, actionCreators)(Register);
export { connectedRegister as Register };