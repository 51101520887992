import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Checkbox } from '@material-ui/core';
import {placesActions} from '../../actions';

class PlaceForm extends Component {

    constructor(props) {
        super(props);
        const {place} = props;
        this.state = {
            id: place.id,
            city: place.city,
            district: place.district,
            sector: place.sector,
            street: place.street,
            house: place.house,
            mailbox_amount: place.mailbox_amount,
            entrance_amount: place.entrance_amount,
            year: place.year,
            floors: place.floors,
            clearCoords: true,
            agent: place.agent
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheck = e => {
        let name = e.target.name;
        let value = e.target.checked;
        this.setState({
            [name]: value
        })
    }

    handleFormSubmit = () => {
        if(this.state.id) {
            this.props.update(this.state);
        } else {
            this.props.save(this.state);
        }
        this.props.closeModal();
    }

    render() {
        return (
            <div className="placeForm">
                <form>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.city} name="city" onChange={this.handleInputChange} label="Город" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.district} name="district" onChange={this.handleInputChange} label="Район" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.sector} name="sector" onChange={this.handleInputChange} label="Сектор" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.street} name="street" onChange={this.handleInputChange} label="Улица" variant="outlined"/>
                </div>      
                <div className="inputHandle">
                    <TextField size="large" value={this.state.house} name="house" onChange={this.handleInputChange} label="Номер дома" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.mailbox_amount} name="mailbox_amount" onChange={this.handleInputChange} label="Кол-во ящиков" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.entrance_amount} name="entrance_amount" onChange={this.handleInputChange} label="Кол-во подъездов" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.year} name="year" onChange={this.handleInputChange} label="Год постройки" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.floors} name="floors" onChange={this.handleInputChange} label="Этажность" variant="outlined"/>
                </div>
                <div className="inputHandle">
                    <TextField size="large" value={this.state.agent} name="agent" onChange={this.handleInputChange} label="Агент" variant="outlined"/>
                </div>
                {this.state.id ?
                <div className="inputHandle">
                    <Checkbox checked={this.state.clearCoords} onChange={this.handleCheck} name="clearCoords"/> Очистить координаты
                </div>:''}
                <Button variant="outlined" onClick={this.handleFormSubmit}>Сохранить</Button>
                </form>
            </div>
        )
        
    }
}

const mapState = (state) => {
    return {state};
}

const actionCreators = {
    save: placesActions.save,
    update: placesActions.update
}

const connectedForm = connect(mapState, actionCreators)(PlaceForm);
export {connectedForm as PlaceForm};

