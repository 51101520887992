import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './Header.css'

class Header extends Component {
    render() {
        console.log(this.props.authentication.loggedIn)
        return (
            <div className="header">
                <div>DEMO v0.1</div>
                {this.props.authentication.loggedIn && <div>
										<Link to="/home">
											<Button variant="outlined" color="default" size="large">home</Button>
										</Link>
                    {this.props.authentication.user.user.role === 'admin' 
											? <Link to="/admin">
                      	  <Button variant="outlined" color="default" size="large">admin</Button>
                    		</Link>
											: ''
										}
                	</div>}
                {this.props.authentication.loggedIn && 
                	<Button variant="outlined" color="default" size="large" onClick={this.props.logout}>выйти</Button>
                }
            </div>
        )
    }
}

const mapState = (state) => {
    const {authentication} = state;
    return {authentication};
}

const actionCreators = {
    logout: userActions.logout
}

const connectedHeader = connect(mapState, actionCreators)(Header);
export {connectedHeader as Header};
