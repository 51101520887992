import React, {Component} from 'react';
import { placesActions } from '../../../actions';
import {connect} from "react-redux";
import {EditPlaceModal, DeleteSubmitModal} from '../../Modal/EditPlaceModal';
import { TextField, Checkbox, Button } from '@material-ui/core';
import {Place} from './Place';

import './PlacePanel.css'

class PlacesPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            openEditModal: false,
            openDeleteModal: false,
            selectedPlace: {},
            options: {
                district: '',
                city: '',
                street: '',
                sector:'',
                house: '',
                duplicate: false,
                noCoords: false
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        var tables = document.getElementsByTagName('table');
        for (var i=0; i<tables.length;i++){
         resizableGrid(tables[i]);
        }
        
        function resizableGrid(table) {
         var row = table.getElementsByTagName('tr')[0],
         cols = row ? row.children : undefined;
         if (!cols) return;
         
         table.style.overflow = 'hidden';
         
         var tableHeight = table.offsetHeight;
         
         for (var i=0;i<cols.length;i++){
          var div = createDiv(tableHeight);
          cols[i].appendChild(div);
          cols[i].style.position = 'relative';
          setListeners(div);
         }
        
         function setListeners(div){
          var pageX,curCol,nxtCol,curColWidth,nxtColWidth;
        
          div.addEventListener('mousedown', function (e) {
           curCol = e.target.parentElement;
           nxtCol = curCol.nextElementSibling;
           pageX = e.pageX; 
         
           var padding = paddingDiff(curCol);
         
           curColWidth = curCol.offsetWidth - padding;
           if (nxtCol)
            nxtColWidth = nxtCol.offsetWidth - padding;
          });
        
          div.addEventListener('mouseover', function (e) {
           e.target.style.borderRight = '2px solid #0000ff';
          })
        
          div.addEventListener('mouseout', function (e) {
           e.target.style.borderRight = '';
          })
        
          document.addEventListener('mousemove', function (e) {
           if (curCol) {
            var diffX = e.pageX - pageX;
         
            if (nxtCol)
             nxtCol.style.width = (nxtColWidth - (diffX))+'px';
        
            curCol.style.width = (curColWidth + diffX)+'px';
           }
          });
        
          document.addEventListener('mouseup', function (e) { 
           curCol = undefined;
           nxtCol = undefined;
           pageX = undefined;
           nxtColWidth = undefined;
           curColWidth = undefined
          });
         }
         
         function createDiv(height){
          var div = document.createElement('div');
          div.style.top = 0;
          div.style.right = 0;
          div.style.width = '5px';
          div.style.position = 'absolute';
          div.style.cursor = 'col-resize';
          div.style.userSelect = 'none';
          div.style.height = height + 'px';
          return div;
         }
         
         function paddingDiff(col){
         
          if (getStyleVal(col,'box-sizing') === 'border-box'){
           return 0;
          }
         
          var padLeft = getStyleVal(col,'padding-left');
          var padRight = getStyleVal(col,'padding-right');
          return (parseInt(padLeft) + parseInt(padRight));
        
         }
        
         function getStyleVal(elm,css){
          return (window.getComputedStyle(elm, null).getPropertyValue(css))
         }
        };
    }

    setOpenEditModal = (action, place = {}) => {
        this.setState({openEditModal: action, selectedPlace: place})
    }

    setOpenDeleteModal = (action, place = {}) => {
        this.setState({openDeleteModal: action, selectedPlace: place})
    }

    deleteButtonHandler = () => {
        this.props._delete(this.state.selectedPlace.id);
        this.setState({openDeleteModal: false, selectedPlace: {}})
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ 
            options: {
                ...this.state.options,
                [name]: value
            }
        });
    }

    handleCheck = (e) => {
        let name = e.target.name;
        let value = e.target.checked;
        
        this.setState({
            options: {
                ...this.state.options,
                [name]: value
            }
        })
    }

    render() {
        return (
            <div>
                <h3>поиск</h3>
                
                <EditPlaceModal open={this.state.openEditModal} setOpen={action=>this.setOpenEditModal(action)} place={this.state.selectedPlace}/>
                <DeleteSubmitModal deleteHandler={this.deleteButtonHandler} open={this.state.openDeleteModal} setOpen={action => this.setOpenDeleteModal(action)} />
                        <Button color="primary" variant="outlined" onClick={() => this.setOpenEditModal(true)}> Добавить место вручную </Button>
                <div>
                <TextField size="small" value={this.state.options.city} name="city" onChange={this.handleInputChange} label="Город" variant="outlined"/>
                <TextField size="small" value={this.state.options.district} name="district" onChange={this.handleInputChange} label="Район" variant="outlined"/>
                <TextField size="small" value={this.state.options.sector} name="sector" onChange={this.handleInputChange} label="Сектор" variant="outlined"/>
                <TextField size="small" value={this.state.options.street} name="street" onChange={this.handleInputChange} label="Улица" variant="outlined"/>
                <TextField size="small" value={this.state.options.house} name="house" onChange={this.handleInputChange} label="№ дома" variant="outlined"/>
                <Checkbox checked={this.state.options.duplicate} name="duplicate" onChange={this.handleCheck}/> дубликаты
                <Checkbox checked={this.state.options.noCoords} name="noCoords" onChange={this.handleCheck}/> без координат
                <div> 
                    <Button variant="outlined" onClick={() => this.props.loadPlaces(this.state.options)}>найти</Button>
                </div>
                <div>
                <table className="placeListTable">
                    <thead><tr>
                        <th>Город</th>
                        <th>Район</th>
                        <th>Сектор</th>
                        <th>Улица</th>
                        <th>Дом</th>
                        <th>Ящики</th>
                        <th>Подъезды</th>
                        <th>Агент</th>
                        <th>Удалить</th>
                        <th>Изменить</th>
                    </tr></thead>
                    <tbody>
                {this.props.places.data.map(place => <Place data={place} editHandler={this.setOpenEditModal} deleteHandler={(place) => this.setOpenDeleteModal(true, place)}/>)}
                </tbody>
                </table> 
                </div>
                    
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const {places} = state;
    return {places}
}

const actionCreators = {
    loadPlaces: placesActions.getPlaces,
    save: placesActions.save,
    _delete: placesActions._delete
};


const connectedPanel = connect(mapState, actionCreators)(PlacesPanel);
export {connectedPanel as PlacesPanel};