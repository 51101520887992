import { adminService } from "../services/admin.service";
import { appConstants } from "../helpers";
import { alertActions } from './';

function loadDoc(doc) {
    return dispatch => {
        dispatch({type: appConstants.DOC_LOAD_REQUEST})

        adminService.loadDoc(doc)
            .then(res => {
                dispatch({type: appConstants.DOC_LOAD_SUCCESS})
                dispatch(alertActions.success('Документ успешно загружен'));
            },
                error => {
                dispatch({type: appConstants.DOC_LOAD_FAILURE});
                dispatch(alertActions.error('Не удалось загрузить документ:' + error.message))
            })
    }
}


export const adminActions = {
    loadDoc
}
