import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import { TextField, Button } from '@material-ui/core';

import './auth.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.props.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitLogin = this.submitLogin.bind(this);

    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    submitLogin(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }

    render(){
        // const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        return(
            <div className="container-fluid">
                <div className="text-center">
                <h2>Авторизация</h2>
                <form name="form">
                    <div className="inputHandle">
                    <TextField size="large" value={this.state.username} display="block" name="username" onChange={this.handleInputChange} label="Имя пользователя" placeholder="username" variant="outlined"/>
                    {submitted && !username && <div className="help-block">Введите имя пользователя</div> }
                    </div>
                    <div className="inputHandle">
                    <TextField value={this.state.password} name="password" onChange={this.handleInputChange} label="Пароль" type="password" variant="outlined"/>
                    </div>
                    {submitted && !password && <div className="help-block">Введите пароль</div> }
                    <div className="buttonHandle">
                    <Button variant="outlined" color="default" size="large" onClick={this.submitLogin} disabled={!username || !password}> Авторизация </Button>
                    </div>
                </form>
                </div>
            </div>

        )
    }
}
function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    getProfile: userActions.getProfile
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };