import { appConstants } from "../helpers/app-constants";

export function admin(state={users: []}, action) {
    switch (action.type) {
        case appConstants.DOC_LOAD_FAILURE:
            return {};
        case appConstants.DOC_LOAD_SUCCESS:
            return {};
        case appConstants.DOC_LOAD_REQUEST:
            return {};
        case appConstants.DELETE_USER_REQUEST:
            return {
                ...state
            };
        case appConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.userId)
            }
        case appConstants.DELETE_USER_FAILURE:
            return {
                ...state,
            }
        case appConstants.GET_USERS_REQUEST:
            return {}
        case appConstants.GET_USERS_SUCCESS:
            return {users: action.users}
        case appConstants.GET_USERS_FAILURE:
            return {}
        default:
            return state
    }
}

