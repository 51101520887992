const baseUrl = process.env.REACT_APP_API_URL;

const loadDoc = async (doc)  => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal()
        },
        body: doc
    }

    await fetch(`${baseUrl}/places/loadDoc`, requestOptions);
}

function getTokenFromLocal() {
    console.log(localStorage.getItem('user'))
    return JSON.parse(localStorage.getItem('user')).user.token;
}

export const adminService = {
    loadDoc
};
