import React from 'react';
import { Button } from '@material-ui/core';

export const User = ({data, deleteHandler}) => (
    <>
    <tr>
        <td>{data.username}</td>
        <td>{data.role}</td>
        <td><Button variant="outlined" onClick={()=>deleteHandler(data.id)}>Удалить</Button></td>
    </tr>
</>
)