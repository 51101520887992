import React from 'react';
import { Button } from '@material-ui/core';

export const Place = ({data, deleteHandler, editHandler}) => (
    <>
        <tr>
            <td>{data.city}</td>
            <td>{data.district}</td>
            <td>{data.sector}</td>
            <td>{data.street}</td>
            <td>{data.house}</td>
            <td>{data.mailbox_amount}</td>
            <td>{data.entrance_amount}</td>
            <td>{data.agent === 'NULL' ? '' : data.agent}</td>
            <td><Button variant="outlined" onClick={()=>deleteHandler(data)}>удалить</Button></td>
            <td><Button variant="outlined" onClick={()=>editHandler(true, data)}>изменить</Button></td>
        </tr>
    </>
)