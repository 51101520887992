import React, { Component } from 'react';
import { connect } from 'react-redux';
import { YMaps, Map as YMap, Polygon, Circle } from 'react-yandex-maps';
import { TextField, MenuItem, Button } from '@material-ui/core';
import { placesActions } from "../../actions";
// import {Script} from './Script'

import './map.css'

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionType: 'polygon',
            flag: true,
            circleRadius: 1000,
            mapCenter: [53.90, 27.55],
            polygonCoords: [],
            circleCenter: []
        }
        this.editor = {};
        
    }

    
    calcButtonHandler = (event) => {
        event.preventDefault();
        if(this.state.selectionType === 'polygon') {
            this.props.calculate({
                type: 'polygon',
                coordinates: this.polygon.geometry.getCoordinates(),
                bounds: this.polygon.geometry.getBounds()
            })
        } else if(this.state.selectionType === 'circle') {
            this.props.calculate({
                type: 'circle',
                center: this.circle.geometry.getCoordinates(),
                radius: this.circle.geometry.getRadius(),
                bounds: this.circle.geometry.getBounds()
            })
        }
    };

    clearButtonHandler = (event) => {
        event.preventDefault();
        this.setState({
            circleCenter: [],
            polygonCoords: []
        })
        this.forceUpdate();
    }

    shouldComponentUpdate(nextProps, nextState) {
        
        nextState.mapCenter = this.map.getCenter();
        return true;
    }


    mapClickHandler = (event) => {
        const coords = event.get('coords');
        this.editor.stopFraming();
        if(this.state.selectionType === 'polygon') {
            this.polygon.editor.stopFraming();
            this.polygon.editor.startEditing(); 
            // this.polygon.editor.startDrawing();
        } else if(this.state.selectionType === 'circle') {
            const circleCoords = this.circle.geometry.getCoordinates();
            if(!circleCoords || isNaN(circleCoords[0])) {
                this.circle.geometry.setCoordinates(null);
                this.circle.geometry.setCoordinates(coords);
                this.circle.geometry.setRadius(this.state.circleRadius);
                this.setState({
                    circleCenter: coords
                })
            }

        }
    };


    handlePolygon = (polygon) => {
        if(!polygon) return;
        this.editor = polygon.editor;

        this.polygon = polygon;
        if(this.polygon.geometry.getCoordinates().length === 0) {
            polygon.editor.startDrawing();
        }
        
        polygon.events.add('dblclick', e => {
            e.preventDefault();
            polygon.editor.startFraming();
        });
        polygon.events.add('contextmenu', e => {
            e.preventDefault();
            polygon.editor.stopFraming();
            polygon.editor.stopDrawing();          
            // console.log(polygon .editor.state._data)
        })
    };

    handleTypeSelection = (event) => {
        event.preventDefault();
        this.setState({
            selectionType: event.target.value
        })
    };

    handleCircle = (circle) => {
        if(!circle) return;
        this.editor = circle.editor;
        this.circle = circle;
        circle.events.add('dblclick', e => {
            e.preventDefault();
            circle.editor.startFraming();
        });
    }

    handleMap = (map) => {
        this.map = map;
        
    }

    handleMapRadiusChange = (e) => {
        this.setState({
            circleRadius: e.target.value
        })
        this.circle.geometry.setRadius(e.target.value);
    }

    render() {
            const selectionType = this.state.selectionType;
        return(
            <div className="map">
                <div className="mapHandler">
                <YMaps  query={{ load: "package.full", apikey: process.env.REACT_APP_YMAPS_API_KEY}}>
                    
                    <YMap
                        onClick={this.mapClickHandler}
                        instanceRef={map => this.handleMap(map)}
                        state={{ center: this.state.mapCenter,  zoom: 12,  controls: ['smallMapDefaultSet'] }}
                        width="-1" height="600px" options={{
                            searchControlProvider: 'yandex#search',
                        }}
                    >
                        {selectionType === 'polygon' &&
                        <Polygon
                            instanceRef={polygon => this.handlePolygon(polygon)}
                            geometry={this.state.polygonCoords}
                            options={{ fillColor: "#00FF00", strokeColor: "#0000FF", opacity: 0.5, strokeWidth: 1, }}
                        /> }
                        {selectionType === 'circle' &&
                            <Circle
                                instanceRef={circle => this.handleCircle(circle)}
                                options={{ fillColor: "#00FF00", strokeColor: "#0000FF", opacity: 0.5, }}
                                geometry={this.state.circleCenter}
                            />
                        }
                    </YMap>
                </YMaps>
                </div>
                <div className="controls">
                    <div>
                        <TextField variant="outlined" id="select" label="Тип области" value={selectionType} select onChange={this.handleTypeSelection}>
                            <MenuItem value="polygon">Многоугольник</MenuItem>
                            <MenuItem value="circle">Круг</MenuItem>
                        </TextField>
                    </div>
                    {this.state.selectionType === 'circle' ? <div>
                        <TextField variant="outlined" type="number" label='радиус (м)' onChange={this.handleMapRadiusChange} value={this.state.circleRadius}/>
                        </div> : ''}
                    <div>
                        <Button onClick={this.calcButtonHandler}>Посчитать</Button>
                    </div>
                    <div>
                        <Button onClick={this.clearButtonHandler}>Очистить</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    return state;
}

const actionCreators = {
    calculate: placesActions.calculate
}

const connectedMap = connect(mapState, actionCreators)(Map);
export {connectedMap as Map};