import { appConstants } from '../helpers/app-constants';

export function places(state = {data: [], error: null}, action) {
    switch (action.type) {
        case appConstants.CALC_REQUEST:
            return {
                ...state,
                type: 'calc_request',
                data: [],
                error: null
            };
        case appConstants.CALC_FAILURE:
            return {
                type: 'calc_failure',
                data: [],
                error: action.error
            };
        case appConstants.CALC_SUCCESS:
            return {
                ...state,
                type: 'calc_success',
                data: action.data,
                error: null
            };
        case appConstants.PLACE_REMOVE:
            return {
                type: 'place_remove',
                data: action.data,
                error: null
            }
        case appConstants.SAVE_PLACE_FAILURE:
            {
                return {
                    ...state,
                    type: 'save_place_failure',
                    res: action.res,
                    err: action.err
                }
            }
        case appConstants.SAVE_PLACE_SUCCESS:
            {
                return {
                    ...state,
                    type: 'save_place_success',
                    res: action.res,
                    err: null
                }
            }
        case appConstants.SAVE_PLACE_REQUEST:
            {
                return {
                    ...state,
                    type: 'save_place_request',
                    res: null,
                    err: null
                }
            }
        case appConstants.GET_PLACES_REQUEST:
            {
                return {
                    type: 'get_place_request',
                    data: [],
                    err: null
                }
            }
        case appConstants.GET_PLACES_SUCCESS:
            {
                return {
                    type: 'get_place_success',
                    data: action.data,
                    err: null
                }
            }
        case appConstants.GET_PLACES_FAILURE:
            {
                return {
                    type: 'get_place_failure',
                    data: [],
                    err: action.err
                }
            }
        case appConstants.DELETE_PLACE_FAILURE:
            {
                return {
                    ...state,
                    res: action.res,
                    type: 'delete_place_failure',
                    err: action.err
                }
            }
        case appConstants.DELETE_PLACE_REQUEST:
            {
                return {
                    ...state,
                    type: 'delete_place_request'
                }
            }
        case appConstants.DELETE_PLACE_SUCCESS:
            {
                return {
                    ...state,
                    type: 'delete_place_success',
                    res: action.res
                }
            }
        default:
            return state
    }
}