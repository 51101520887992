import React from 'react';

export const Place = ({data, deleteHandler}) => (
    <>
        <tr>
            <td>{data.city}</td>
            <td>{data.district}</td>
            <td>{data.sector}</td>
            <td>{data.street}</td>
            <td>{data.house}</td>
            <td>{data.mailbox_amount}</td>
            <td>{data.entrance_amount}</td>
            <td>{data.agent==='NULL' ? '' : data.agent}</td>
            <td><button onClick={()=>deleteHandler(data.id)}>X</button></td>
        </tr>
    </>
)