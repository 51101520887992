import {userActions} from '../../actions/user.action';
// import {history} from '../../helpers';

export const handleResponse = async (response) => {
    // if(!response.ok) {
    //     console.log('not ok')
    //     const { message } = await response.json();
    //     throw new Error('Ошибка:' + message);
    // }
    // return response;

    switch(response.status) {
        case 200:
            return response.json();
        case 401:
            userActions.logout();
            throw new Error('Ошибка авторизации')
        case 400:
            userActions.logout();
            throw new Error('Ошибка:' + (await response.json()).message)
        default:
            return response.json()
    }
}