import {handleResponse} from './utils/handleResponse';

const baseUrl = process.env.REACT_APP_API_URL;

const calculate = async (data) => {
    console.log(process.env)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal(),
            'Content-type':'application/json'
        },
        body: JSON.stringify(data)
    }
    return await fetch(`${baseUrl}/places/calculate`, requestOptions).then(handleResponse);
}

const save = async (place) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal(),
            'Content-type':'application/json'
        },
        body: JSON.stringify({place})
    }
    return await fetch(`${baseUrl}/places`, requestOptions).then(handleResponse);
}

const get = async (options) => {
    var esc = encodeURIComponent;
    var query = Object.keys(options)
    .map(k => esc(k) + '=' + esc(options[k]))
    .join('&');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal(),
            'Content-type':'application/json'
        }    
    }
    return await fetch(`${baseUrl}/places?${query}`, requestOptions).then(handleResponse);
}

const update = async (place) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal(),
            'Content-type':'application/json'
        },
        body: JSON.stringify({place})
    }
    return await fetch(`${baseUrl}/places`, requestOptions).then(handleResponse);
}

const remove =  async (id) => {
    const requestOptions= {
        method: 'DELETE',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal(),
            'Content-type':'application/json'
        },
        body: JSON.stringify({id})
    }
    return await fetch(`${baseUrl}/places`, requestOptions).then(handleResponse);
}

function getTokenFromLocal() {
    return JSON.parse(localStorage.getItem('user')).user.token;
}

const downloadDoc = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Token ' + getTokenFromLocal(),
            'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    }

    return await fetch(`${baseUrl}/places/load-doc`, requestOptions).then(doc => doc.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Doc.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    });
}

export const placesService = {
    calculate,
    save,
    get,
    update,
    remove,
    downloadDoc
}