import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const userData = localStorage.getItem('user');
        if(!userData) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />; 
        } else {
            const {user} = JSON.parse(userData);
            console.log(user);
            if(rest.roles.indexOf(user.role) === -1) {
                let redirectPath = '/home';
                if(user.role === 'admin') {
                    redirectPath = '/admin'
                }
                if(props.history.location.pathname !== redirectPath) {
                    return <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />;         
                }
            }
            return <Component {...props} />
        }
    }} />
)